import React from 'react';

import { IconButton } from '../../../index';
import { ModalTypes } from './Modal.types';
import { StyledDialog } from './Modal.styles';

export const Modal = ({
  headerContent,
  bodyContent,
  footerContent,
  isFullScreen,
  isOpen,
  handleExit,
  closeButton,
  classes,
  size = 'small',
  width = '680px',
  className,
  disableEscapeKeyDown,
}: ModalTypes): JSX.Element => (
  <StyledDialog
    open={isOpen}
    onClose={() => handleExit()}
    disableEnforceFocus
    fullScreen={isFullScreen}
    classes={classes}
    className={`size-${size} ${className}`}
    width={width}
    disableEscapeKeyDown={disableEscapeKeyDown}
  >
    <div className='modal-header'>
      <div className='header-wrapper'>{headerContent}</div>
      <div className='exit-button-wrapper'>
        {closeButton || (
          <IconButton
            ariaLabel='exit button'
            className='exit-button'
            icon='Close'
            onClick={handleExit}
          />
        )}
      </div>
    </div>
    <div className='modal-body'>{bodyContent}</div>
    <div className='modal-footer'>{footerContent}</div>
  </StyledDialog>
);
