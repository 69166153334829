import React from 'react';
import { DraftailPreview } from '../../DraftailComponents/DraftailPreview';
import MobileCase from '../../Assets/Svgs/MobileCase/MobileCase.svg';
import MobileCaseWhatsapp from '../../Assets/Svgs/MobileCase/MobileCaseWhatsapp.svg';
import { WebPreviewer } from '../../Atoms/WebPreviewer';
import { MobilePreviewTypes } from './MobilePreview.types';
import { MobilePreviewWrapper } from './MobilePreview.styles';

const carriageReturnDisplay = (message: string) =>
  message.replace(/<\s*p[^>]*><\s*\/\s*p\s*>/g, '<p>&nbsp</p>');

export const MobilePreview = ({
  type = 'sms',
  message,
  media,
}: MobilePreviewTypes): JSX.Element => {
  const Case = type === 'whatsapp' ? MobileCaseWhatsapp : MobileCase;

  return (
    <MobilePreviewWrapper>
      <Case className='mobile-case' />
      <div className={['scroll-wrapper', type].join(' ')}>
        {type !== 'web' && typeof message === 'string' && message.length > 0 && (
          <div className='text-bubble'>
            {media && media}
            {/* Injecting input (as markup) to render as html in order to pring 
            carriage-returns in the UI mobile preview display */}
            <DraftailPreview
              htmlContentString={carriageReturnDisplay(message)}
            />
          </div>
        )}
        {type === 'web' && (
          <div className='web-content'>
            <WebPreviewer
              content={message}
              deviceWidth={375} // iPhone 6/7/8
            />
          </div>
        )}
      </div>
    </MobilePreviewWrapper>
  );
};
