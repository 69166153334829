import { Menu, MenuProps } from '@material-ui/core';

import React, {
  useRef,
  useState,
  RefAttributes,
  PropsWithoutRef,
  ForwardRefExoticComponent,
  MouseEvent,
} from 'react';
import { IconTypes, Icon } from '../../Atoms/Icon';

import { StyledMenuItem } from './PopoverMenuBase.styles';

type PopoverMenuItem<T> = {
  icon?: IconTypes;
  label: string;
  onClick: (data: T) => void;
  data?: T;
};

export type PopoverMenuBaseProps = {
  // If copied this from the return type of the `forwardRef` function. It would be nice if there was a convenience type for it.
  TriggerComponent: ForwardRefExoticComponent<
    PropsWithoutRef<{ onClick }> & RefAttributes<HTMLButtonElement>
  >;
  menuItems: Array<PopoverMenuItem<unknown>>;
  anchorOrigin?: MenuProps['anchorOrigin'];
};

export type PopoverMenuItemProps = {
  icon?: IconTypes;
  label: string;
  onClick: (event: MouseEvent) => void;
};

/**
 * The forwardRef error that is occuring is probably this:
 * https://github.com/mui-org/material-ui/issues/15903
 * @param props
 */
export const PopoverMenuItem = (props: PopoverMenuItemProps): JSX.Element => {
  const { icon, label, onClick } = props;
  return (
    <StyledMenuItem onClick={onClick}>
      {icon && <Icon icon={icon} />}
      {label}
    </StyledMenuItem>
  );
};

/**
 * This is the fully customisable PopoverMenu
 * Use this if you need to use a customised trigger
 * But probably the 'Simple' versions will do what you need if you just need a Button or IconButton as a trigger.
 * @param props
 */
export const PopoverMenuBase = (props: PopoverMenuBaseProps): JSX.Element => {
  const {
    TriggerComponent,
    menuItems,
    anchorOrigin = {
      horizontal: 'center',
      vertical: 'bottom',
    },
  } = props;
  const ref = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleTriggerClick = (event: MouseEvent) => {
    event.stopPropagation(); // Stop the event from causing clicks up the tree - on table rows for example
    setIsOpen(true);
  };

  const handleClose = (event: MouseEvent) => {
    event.stopPropagation();
    setIsOpen(false);
  };

  return (
    <>
      {isOpen}
      <TriggerComponent ref={ref} onClick={handleTriggerClick} />
      {ref.current && (
        <Menu
          anchorEl={ref.current}
          open={isOpen}
          onClose={handleClose}
          anchorOrigin={anchorOrigin}
          getContentAnchorEl={null}
        >
          <span>
            {menuItems.map((v) => (
              <PopoverMenuItem
                label={v.label}
                icon={v.icon}
                key={v.label}
                onClick={(event: MouseEvent) => {
                  handleClose(event);
                  v.onClick(v.data);
                }}
              />
            ))}
          </span>
        </Menu>
      )}
    </>
  );
};
