import Accordian from '../../Assets/Svgs/Accordian.svg';
import AccountCircle from '../../Assets/Svgs/AccountCircle.svg';
import AccountCircleOutline from '../../Assets/Svgs/AccountCircleOutline.svg';
import Add from '../../Assets/Svgs/Add.svg';
import AddImage from '../../Assets/Svgs/AddImage.svg';
import AddToCalendar from '../../Assets/Svgs/AddToCalendar.svg';
import AddToList from '../../Assets/Svgs/AddToList.svg';
import AlertUser from '../../Assets/Svgs/AlertUser.svg';
import AlignmentLeft from '../../Assets/Svgs/AlignmentLeft.svg';
import AlignmentMiddle from '../../Assets/Svgs/AlignmentMiddle.svg';
import AlignmentRight from '../../Assets/Svgs/AlignmentRight.svg';
import Analytics from '../../Assets/Svgs/Analytics.svg';
import AnalyticsOutline from '../../Assets/Svgs/AnalyticsOutline.svg';
import Api from '../../Assets/Svgs/Api.svg';
import ApiKeys from '../../Assets/Svgs/ApiKeys.svg';
import Archive from '../../Assets/Svgs/Archive.svg';
import ArrowRight from '../../Assets/Svgs/ArrowRight.svg';
import Assessment from '../../Assets/Svgs/Assessment.svg';
import Badge from '../../Assets/Svgs/Badge.svg';
import Banner from '../../Assets/Svgs/Banner.svg';
import Billing from '../../Assets/Svgs/Billing.svg';
import Branch from '../../Assets/Svgs/Branch.svg';
import BranchContainer from '../../Assets/Svgs/BranchContainer.svg';
import BranchDiamond from '../../Assets/Svgs/BranchDiamond.svg';
import BulletedList from '../../Assets/Svgs/BulletedList.svg';
import Button from '../../Assets/Svgs/Button.svg';
import ButtonGroup from '../../Assets/Svgs/ButtonGroup.svg';
import Calendar from '../../Assets/Svgs/Calendar.svg';
import Checkbox from '../../Assets/Svgs/Checkbox.svg';
import CheckboxList from '../../Assets/Svgs/CheckboxList.svg';
import ChevronLeft from '../../Assets/Svgs/ChevronLeft.svg';
import ChevronRight from '../../Assets/Svgs/ChevronRight.svg';
import ClassicThumbnail from '../../Assets/Svgs/ClassicThumbnail.svg';
import Close from '../../Assets/Svgs/Close.svg';
import CloseCircle from '../../Assets/Svgs/CloseCircle.svg';
import CloudUpload from '../../Assets/Svgs/CloudUpload.svg';
import Code from '../../Assets/Svgs/Code.svg';
import Cog from '../../Assets/Svgs/Cog.svg';
import Columns from '../../Assets/Svgs/Columns.svg';
import Comment from '../../Assets/Svgs/Comment.svg';
import Company from '../../Assets/Svgs/Company.svg';
import Condition from '../../Assets/Svgs/Condition.svg';
import ContactOutline from '../../Assets/Svgs/ContactOutline.svg';
import ContactPortal from '../../Assets/Svgs/ContactPortal.svg';
import ContactPrivacy from '../../Assets/Svgs/ContactPrivacy.svg';
import Contacts from '../../Assets/Svgs/Contacts.svg';
import ContactsOutline from '../../Assets/Svgs/ContactsOutline.svg';
import Copy from '../../Assets/Svgs/Copy.svg';
import CustomCode from '../../Assets/Svgs/CustomCode.svg';
import CustomModule from '../../Assets/Svgs/CustomModule.svg';
import DailyMotion from '../../Assets/Svgs/DailyMotion.svg';
import Date from '../../Assets/Svgs/Date.svg';
import DateInput from '../../Assets/Svgs/DateInput.svg';
import Decrease from '../../Assets/Svgs/Decrease.svg';
import DefaultTrigger from '../../Assets/Svgs/DefaultTrigger.svg';
import Delete from '../../Assets/Svgs/Delete.svg';
import DeleteBranch from '../../Assets/Svgs/DeleteBranch.svg';
import Desktop from '../../Assets/Svgs/Desktop.svg';
import Devices from '../../Assets/Svgs/Devices.svg';
import Disengaged from '../../Assets/Svgs/Disengaged.svg';
import Divider from '../../Assets/Svgs/Divider.svg';
import Dkim from '../../Assets/Svgs/Dkim.svg';
import Dot from '../../Assets/Svgs/Dot.svg';
import DownArrow from '../../Assets/Svgs/DownArrow.svg';
import DragHandle from '../../Assets/Svgs/DragHandle.svg';
import DropDown from '../../Assets/Svgs/DropDown.svg';
import DropdownArrow from '../../Assets/Svgs/DropdownArrow.svg';
import DropDownList from '../../Assets/Svgs/DropDownList.svg';
import DropupArrow from '../../Assets/Svgs/DropupArrow.svg';
import EditPen from '../../Assets/Svgs/EditPen.svg';
import EditPenOutline from '../../Assets/Svgs/EditPenOutline.svg';
import Elipsis from '../../Assets/Svgs/Elipsis.svg';
import Email from '../../Assets/Svgs/Email.svg';
import EmailDisabled from '../../Assets/Svgs/EmailDisabled.svg';
import EmailOutline from '../../Assets/Svgs/EmailOutline.svg';
import EmailToSms from '../../Assets/Svgs/EmailToSms.svg';
import EmailTrigger from '../../Assets/Svgs/EmailTrigger.svg';
import Engaged from '../../Assets/Svgs/Engaged.svg';
import Error from '../../Assets/Svgs/Error.svg';
import ErrorOutline from '../../Assets/Svgs/ErrorOutline.svg';
import Exit from '../../Assets/Svgs/Exit.svg';
import ExpandLess from '../../Assets/Svgs/ExpandLess.svg';
import ExpandMore from '../../Assets/Svgs/ExpandMore.svg';
import Export from '../../Assets/Svgs/Export.svg';
import Eye from '../../Assets/Svgs/Eye.svg';
import Facebook from '../../Assets/Svgs/Facebook.svg';
import Filter from '../../Assets/Svgs/Filter.svg';
import Footer from '../../Assets/Svgs/Footer.svg';
import FormTrigger from '../../Assets/Svgs/FormTrigger.svg';
import FullWidthBanner from '../../Assets/Svgs/FullWidthBanner.svg';
import GoTo from '../../Assets/Svgs/GoTo.svg';
import HelpWheel from '../../Assets/Svgs/HelpWheel.svg';
import Home from '../../Assets/Svgs/Home.svg';
import HostedMP4 from '../../Assets/Svgs/HostedMP4.svg';
import IconAlignmentLeft from '../../Assets/Svgs/IconAlignmentLeft.svg';
import IconAlignmentRight from '../../Assets/Svgs/IconAlignmentRight.svg';
import If from '../../Assets/Svgs/If.svg';
import Image from '../../Assets/Svgs/Image.svg';
import ImageCopy from '../../Assets/Svgs/ImageCopy.svg';
import Increase from '../../Assets/Svgs/Increase.svg';
import Info from '../../Assets/Svgs/Info.svg';
import InfoOutline from '../../Assets/Svgs/InfoOutline.svg';
import InkPen from '../../Assets/Svgs/InkPen.svg';
import InkPenOutline from '../../Assets/Svgs/InkPenOutline.svg';
import Iux from '../../Assets/Svgs/Iux.svg';
import Launch from '../../Assets/Svgs/Launch.svg';
import Lightning from '../../Assets/Svgs/Lightning.svg';
import Link from '../../Assets/Svgs/Link.svg';
import LinkOut from '../../Assets/Svgs/LinkOut.svg';
import Location from '../../Assets/Svgs/Location.svg';
import LocationOutline from '../../Assets/Svgs/LocationOutline.svg';
import Lock from '../../Assets/Svgs/Lock.svg';
import Logo from '../../Assets/Svgs/Logo.svg';
import LogoBeta from '../../Assets/Svgs/LogoBeta.svg';
import LogoDots from '../../Assets/Svgs/LogoDots.svg';
import LogoSmall from '../../Assets/Svgs/LogoSmall.svg';
import LoneWorker from '../../Assets/Svgs/LoneWorker.svg';
import LongText from '../../Assets/Svgs/LongText.svg';
import Manual from '../../Assets/Svgs/Manual.svg';
import ManualTrigger from '../../Assets/Svgs/ManualTrigger.svg';
import Map from '../../Assets/Svgs/Map.svg';
import Mapping from '../../Assets/Svgs/Mapping.svg';
import Medical from '../../Assets/Svgs/Medical.svg';
import Meeting from '../../Assets/Svgs/Meeting.svg';
import Menu from '../../Assets/Svgs/Menu.svg';
import Message from '../../Assets/Svgs/Message.svg';
import MessageDisabled from '../../Assets/Svgs/MessageDisabled.svg';
import Messages from '../../Assets/Svgs/Messages.svg';
import MessagesOutline from '../../Assets/Svgs/MessagesOutline.svg';
import MessangerInactive from '../../Assets/Svgs/MessangerInactive.svg';
import Metrics from '../../Assets/Svgs/Metrics.svg';
import Mms from '../../Assets/Svgs/Mms.svg';
import Mobile from '../../Assets/Svgs/Mobile.svg';
import Move from '../../Assets/Svgs/Move.svg';
import Multilingual from '../../Assets/Svgs/Multilingual.svg';
import NewRelease from '../../Assets/Svgs/NewRelease.svg';
import NumberedList from '../../Assets/Svgs/NumberedList.svg';
import Onboarding from '../../Assets/Svgs/Onboarding.svg';
import Open from '../../Assets/Svgs/Open.svg';
import ParagraphAlignCentre from '../../Assets/Svgs/ParagraphAlignCentre.svg';
import ParagraphAlignLeft from '../../Assets/Svgs/ParagraphAlignLeft.svg';
import ParagraphAlignRight from '../../Assets/Svgs/ParagraphAlignRight.svg';
import Person from '../../Assets/Svgs/Person.svg';
import PlaceholderImage from '../../Assets/Svgs/PlaceholderImage.svg';
import Plus from '../../Assets/Svgs/Plus.svg';
import Processing from '../../Assets/Svgs/Processing.svg';
import Publish from '../../Assets/Svgs/Publish.svg';
import Push from '../../Assets/Svgs/Push.svg';
import Pushie from '../../Assets/Svgs/Pushie.svg';
import QuestionOutline from '../../Assets/Svgs/QuestionOutline.svg';
import RadioList from '../../Assets/Svgs/RadioList.svg';
import Rating from '../../Assets/Svgs/Rating.svg';
import Received from '../../Assets/Svgs/Received.svg';
import Recipients from '../../Assets/Svgs/Recipients.svg';
import Remove from '../../Assets/Svgs/Remove.svg';
import RemoveFromList from '../../Assets/Svgs/RemoveFromList.svg';
import Reply from '../../Assets/Svgs/Reply.svg';
import ReplyOutline from '../../Assets/Svgs/ReplyOutline.svg';
import Rostering from '../../Assets/Svgs/Rostering.svg';
import Rss from '../../Assets/Svgs/Rss.svg';
import SalesForceTrigger from '../../Assets/Svgs/SalesForceTrigger.svg';
import Schedule from '../../Assets/Svgs/Schedule.svg';
import Search from '../../Assets/Svgs/Search.svg';
import SentimentDissatisfied from '../../Assets/Svgs/SentimentDissatisfied.svg';
import SentimentSatisfied from '../../Assets/Svgs/SentimentSatisfied.svg';
import SentimentSatisfiedAlt from '../../Assets/Svgs/SentimentSatisfiedAlt.svg';
import SentimentVeryDissatisfied from '../../Assets/Svgs/SentimentVeryDissatisfied.svg';
import SentimentVerySatisfied from '../../Assets/Svgs/SentimentVerySatisfied.svg';
import ServiceNow from '../../Assets/Svgs/ServiceNow.svg';
import Signature from '../../Assets/Svgs/Signature.svg';
import Slider from '../../Assets/Svgs/Slider.svg';
import SmartReview from '../../Assets/Svgs/SmartReview.svg';
import SmsOutline from '../../Assets/Svgs/SmsOutline.svg';
import SMSTrigger from '../../Assets/Svgs/SMSTrigger.svg';
import Social from '../../Assets/Svgs/Social.svg';
import SocialNew from '../../Assets/Svgs/SocialNew.svg';
import Sort from '../../Assets/Svgs/Sort.svg';
import SortArrows from '../../Assets/Svgs/SortArrows.svg';
import Spf from '../../Assets/Svgs/Spf.svg';
import Spinner from '../../Assets/Svgs/Spinner.svg';
import Sso from '../../Assets/Svgs/Sso.svg';
import Star from '../../Assets/Svgs/Star.svg';
import StatusDownDark from '../../Assets/Svgs/StatusDownDark.svg';
import StatusDownLight from '../../Assets/Svgs/StatusDownLight.svg';
import StatusTick from '../../Assets/Svgs/StatusTick.svg';
import StatusUpDark from '../../Assets/Svgs/StatusUpDark.svg';
import StatusUpLight from '../../Assets/Svgs/StatusUpLight.svg';
import Store from '../../Assets/Svgs/Store.svg';
import Submit from '../../Assets/Svgs/Submit.svg';
import SubscribedList from '../../Assets/Svgs/SubscribedList.svg';
import SystemSettings from '../../Assets/Svgs/SystemSettings.svg';
import Templates from '../../Assets/Svgs/Templates.svg';
import Text from '../../Assets/Svgs/Text.svg';
import TextAndImage from '../../Assets/Svgs/TextAndImage.svg';
import TextField from '../../Assets/Svgs/TextField.svg';
import TextInput from '../../Assets/Svgs/TextInput.svg';
import ThumbsDown from '../../Assets/Svgs/ThumbsDown.svg';
import ThumbsUp from '../../Assets/Svgs/ThumbsUp.svg';
import Tick from '../../Assets/Svgs/Tick.svg';
import Tip from '../../Assets/Svgs/Tip.svg';
import TokBox from '../../Assets/Svgs/TokBox.svg';
import ToneOfVoice from '../../Assets/Svgs/ToneofVoice.svg';
import Training from '../../Assets/Svgs/Training.svg';
import UnderConstruction from '../../Assets/Svgs/UnderConstruction.svg';
import UpArrow from '../../Assets/Svgs/UpArrow.svg';
import Upgrade from '../../Assets/Svgs/Upgrade.svg';
import Upload from '../../Assets/Svgs/Upload.svg';
import UploadSuccess from '../../Assets/Svgs/UploadSuccess.svg';
import Video from '../../Assets/Svgs/Video.svg';
import VideoPlayerPlaceholder from '../../Assets/Svgs/VideoPlayerPlaceholder.svg';
import Vimeo from '../../Assets/Svgs/Vimeo.svg';
import Voice from '../../Assets/Svgs/Voice.svg';
import VoiceInactive from '../../Assets/Svgs/VoiceInactive.svg';
import VoiceOutline from '../../Assets/Svgs/VoiceOutline.svg';
import Warning from '../../Assets/Svgs/Warning.svg';
import WarningOutline from '../../Assets/Svgs/WarningOutline.svg';
import Web from '../../Assets/Svgs/Web.svg';
import WebForm from '../../Assets/Svgs/WebForm.svg';
import WebformTrigger from '../../Assets/Svgs/WebformTrigger.svg';
import WebhookTrigger from '../../Assets/Svgs/WebhookTrigger.svg';
import WebOutline from '../../Assets/Svgs/WebOutline.svg';
import Whatsapp from '../../Assets/Svgs/Whatsapp.svg';
import WhatsappInactive from '../../Assets/Svgs/WhatsappInactive.svg';
import WhatsappOutline from '../../Assets/Svgs/WhatsappOutline.svg';
import Wistia from '../../Assets/Svgs/Wistia.svg';
import Workflow from '../../Assets/Svgs/Workflow.svg';
import WorkflowOutline from '../../Assets/Svgs/WorkflowOutline.svg';
import YesNo from '../../Assets/Svgs/YesNo.svg';
import Youtube from '../../Assets/Svgs/Youtube.svg';

export const Icons = {
  Accordian,
  AccountCircle,
  AccountCircleOutline,
  Add,
  AddImage,
  AddToCalendar,
  AddToList,
  AlertUser,
  AlignmentLeft,
  AlignmentMiddle,
  AlignmentRight,
  Analytics,
  AnalyticsOutline,
  Api,
  ApiKeys,
  Archive,
  ArrowRight,
  Assessment,
  Badge,
  Banner,
  Billing,
  Branch,
  BranchContainer,
  BranchDiamond,
  BulletedList,
  Button,
  ButtonGroup,
  Calendar,
  Checkbox,
  CheckboxList,
  ChevronLeft,
  ChevronRight,
  ClassicThumbnail,
  Close,
  CloseCircle,
  CloudUpload,
  Code,
  Cog,
  Columns,
  Comment,
  Company,
  Condition,
  ContactOutline,
  ContactPortal,
  ContactPrivacy,
  Contacts,
  ContactsOutline,
  Copy,
  CustomCode,
  CustomModule,
  DailyMotion,
  Date,
  DateInput,
  Decrease,
  DefaultTrigger,
  Delete,
  DeleteBranch,
  Desktop,
  Devices,
  Disengaged,
  Divider,
  Dkim,
  Dot,
  DownArrow,
  DragHandle,
  DropDown,
  DropdownArrow,
  DropDownList,
  DropupArrow,
  EditPen,
  EditPenOutline,
  Elipsis,
  Email,
  EmailDisabled,
  EmailOutline,
  EmailToSms,
  EmailTrigger,
  Engaged,
  Error,
  ErrorOutline,
  Exit,
  ExpandLess,
  ExpandMore,
  Export,
  Eye,
  Facebook,
  Filter,
  Footer,
  FormTrigger,
  FullWidthBanner,
  GoTo,
  HelpWheel,
  Home,
  HostedMP4,
  IconAlignmentLeft,
  IconAlignmentRight,
  If,
  Image,
  ImageCopy,
  Increase,
  Info,
  InfoOutline,
  InkPen,
  InkPenOutline,
  Iux,
  Launch,
  Lightning,
  Link,
  LinkOut,
  Location,
  LocationOutline,
  Lock,
  Logo,
  LogoBeta,
  LogoDots,
  LogoSmall,
  LoneWorker,
  LongText,
  Manual,
  ManualTrigger,
  Map,
  Mapping,
  Medical,
  Meeting,
  Menu,
  Message,
  MessageDisabled,
  Messages,
  MessagesOutline,
  MessangerInactive,
  Metrics,
  Mms,
  Mobile,
  Move,
  Multilingual,
  NewRelease,
  NumberedList,
  Onboarding,
  Open,
  ParagraphAlignCentre,
  ParagraphAlignLeft,
  ParagraphAlignRight,
  PlaceholderImage,
  Person,
  Plus,
  Processing,
  Publish,
  Push,
  Pushie,
  QuestionOutline,
  RadioList,
  Rating,
  Received,
  Recipients,
  Remove,
  RemoveFromList,
  Reply,
  ReplyOutline,
  Rostering,
  Rss,
  SalesForceTrigger,
  Schedule,
  Search,
  SentimentDissatisfied,
  SentimentSatisfied,
  SentimentSatisfiedAlt,
  SentimentVeryDissatisfied,
  SentimentVerySatisfied,
  ServiceNow,
  Signature,
  Slider,
  SmartReview,
  SmsOutline,
  SMSTrigger,
  Social,
  SocialNew,
  Sort,
  SortArrows,
  Spf,
  Spinner,
  Sso,
  Star,
  StatusDownDark,
  StatusDownLight,
  StatusTick,
  StatusUpDark,
  StatusUpLight,
  Store,
  Submit,
  SubscribedList,
  SystemSettings,
  Templates,
  Text,
  TextAndImage,
  TextField,
  TextInput,
  ThumbsDown,
  ThumbsUp,
  Tick,
  Tip,
  TokBox,
  ToneOfVoice,
  Training,
  UnderConstruction,
  UpArrow,
  Upgrade,
  Upload,
  UploadSuccess,
  Video,
  VideoPlayerPlaceholder,
  Vimeo,
  Voice,
  VoiceInactive,
  VoiceOutline,
  Warning,
  WarningOutline,
  Web,
  WebForm,
  WebformTrigger,
  WebhookTrigger,
  WebOutline,
  Whatsapp,
  WhatsappInactive,
  WhatsappOutline,
  Wistia,
  Workflow,
  WorkflowOutline,
  YesNo,
  Youtube,
};
