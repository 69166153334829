import React, { useState, useEffect, useRef } from 'react';
import { WebPreviewerTypes } from './WebPreviewer.types';
import { WebPreviewerWrapper } from './WebPreviewer.styles';

export const WebPreviewer = ({
  content,
  deviceWidth = 1300,
}: WebPreviewerTypes): JSX.Element => {
  const contentRef = useRef(null);
  const [style, setStyle] = useState({});

  useEffect(() => {
    if (!contentRef || !contentRef.current) return;

    const scale = (contentRef.current.clientWidth || deviceWidth) / deviceWidth;
    const height = contentRef.current.clientHeight / scale;
    setStyle({
      width: deviceWidth,
      height,
      transform: `scale(${scale}`,
      transformOrigin: 'top left',
      overflow: 'auto',
    });
  }, [contentRef, deviceWidth]);

  return (
    <WebPreviewerWrapper ref={contentRef}>
      <div style={style}>{content}</div>
    </WebPreviewerWrapper>
  );
};
