import React from 'react';
import { WebPreviewer } from '../../Atoms/WebPreviewer';
import { DesktopPreviewTypes } from './DesktopPreview.types';
import { DesktopPreviewWrapper } from './DesktopPreview.styles';

export const DesktopPreview = ({
  content,
  desktopWidth = 1300, // medium desktop width
}: DesktopPreviewTypes): JSX.Element => (
  <DesktopPreviewWrapper>
    <div className='header'>
      <div />
      <div />
      <div />
    </div>
    {/* render three circles */}
    <div className='content'>
      <WebPreviewer content={content} deviceWidth={desktopWidth} />
    </div>
  </DesktopPreviewWrapper>
);
