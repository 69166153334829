import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';

const desktopHeaderHeight = '42px';

export const DesktopPreviewWrapper = styled.div`
  width: 832px;
  height: 818px;
  border: 1px solid ${Colors.grey_blue_2};
  border-radius: 8px;
  box-sizing: border-box;

  .header {
    display: flex;
    align-items: center;
    height: ${desktopHeaderHeight};
    border-bottom: 1px solid ${Colors.grey_blue_2};
    gap: 8px;
    padding-left: 16px;

    div {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 1px solid ${Colors.grey_blue_2};
    }
  }

  .content {
    width: 100%;
    height: calc(100% - ${desktopHeaderHeight});
    border-radius: 0 0 8px 8px;
    overflow: hidden;
  }
`;
