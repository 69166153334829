import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const CodeEditorWrapper = styled.div<{ theme: string }>`
  [role='tabpanel'] {
    padding: 16px 0 24px;
  }

  .custom-code-editor-combined {
    section {
      overflow: hidden;
    }
  }

  .heading {
    color: ${Colors.grey_blue};
    ${Fonts.link};
  }

  .react-monaco-editor-container {
    padding-top: 8px;
  }

  .monaco-editor,
  .monaco-editor-background,
  .monaco-editor .inputarea.ime-input {
    background-color: ${(props) =>
      props.theme === 'vs-light' && Colors.grey_blue_0};
    .margin,
    .minimap,
    .minimap-decorations-layer {
      background-color: ${(props) =>
        props.theme === 'vs-light' && Colors.grey_blue_0};
    }
    .monaco-scrollable-element,
    .editor-scrollable,
    .vs,
    .mac {
      border-left: ${(props) =>
        props.theme === 'vs-light' && `1px solid ${Colors.grey_blue_1}`};
    }
  }

  .custom-code-tab-actions {
    display: flex;
    align-items: center;
  }
  .monaco-editor .lines-content.monaco-editor-background {
    margin-left: 8px;
  }
`;
