import React, { PureComponent } from 'react';
import { FormHelperText } from '@material-ui/core';
import {
  toHTML,
  fromHTML,
  validateRawContentStateForInvalidVariables,
} from '../draftailUtils';
import { DraftailEditorWithVariables } from '../DraftailEditorWithVariables';
import { VariableTextInputProps } from './VariableTextInput.types';
import { StyledVariableTextInput } from './VariableTextInput.style';

type State = {
  textPropertyValue: string;
  focus: boolean;
};

/**
 * @legacy
 *
 *
 * This is a legacy component that used by the DragAndDrop components,
 * not directly, but in the right hand panel, (ie. DraggableItemProperties)
 *
 * It retains the existing legacy component signature.
 *
 * It is basically a text input, with a button to allow the selection of variables.
 *
 * We can probably create an abstraction for this (like the GenericTextAreaWithVariables), but it's currently not needed.
 */
export class VariableTextInput extends PureComponent<
  VariableTextInputProps,
  State
> {
  constructor(props: VariableTextInputProps) {
    super(props);
    const { variableTextInputType, defaultTitle, defaultValue } = this.props;
    let initialTextValue = defaultValue;

    if (variableTextInputType === 'email' || variableTextInputType === 'web') {
      if (!initialTextValue) {
        initialTextValue = defaultTitle;
      }
    }

    this.state = {
      focus: false,
      textPropertyValue: initialTextValue,
    };
  }

  handleFocus = (): void => {
    this.setState({ focus: true });
  };

  handleBlur = (): void => {
    this.setState({ focus: false });
  };

  onSaveHandler = (raw: unknown): void => {
    const { onChange, variableTextInputType } = this.props;
    const fieldContent = toHTML(raw);

    // remove wrapped <p> tags that are auto injected by draftail so that DnD component UI
    // renders are not effected - while still retaining all <span> data for variable transformation
    const filteredFieldContent = fieldContent.replace(/<\/?p[^>]*>/g, '');

    switch (variableTextInputType) {
      case 'web':
      case 'email':
      case 'webform':
        onChange(variableTextInputType, fieldContent);
        break;
      case 'componentPropertyInput':
      case 'whatsapp':
        onChange(filteredFieldContent);
        break;
      default:
        throw new Error(
          `Variable input type: ${variableTextInputType} not found 🚫`
        );
    }
  };

  renderLabel = (): string => {
    const { variableTextInputType, label } = this.props;
    switch (variableTextInputType) {
      case 'email':
        return 'Subject Title';
      case 'web':
        return 'Page Title';
      case 'componentPropertyInput':
        return label || '';
      default:
        return '';
    }
  };

  render(): JSX.Element {
    const {
      variableMenuData,
      variableTextInputType,
      activeChannel,
      placeholder = 'Enter Text...',
      helperText,
    } = this.props;
    const { textPropertyValue } = this.state;

    const validatedDratailObject = validateRawContentStateForInvalidVariables({
      rawContentState: fromHTML(textPropertyValue),
      variableGroups: variableMenuData,
    });

    // TODO/CLEANUP - remove this whatsapp/title specific styling.
    // Instead pass a classname in and do any specific styling from the parent.
    return (
      <StyledVariableTextInput
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        isTitle={variableTextInputType !== 'componentPropertyInput'}
        isWhatsapp={variableTextInputType === 'whatsapp'}
      >
        <div className='variable-picker-field--label'>{this.renderLabel()}</div>
        <DraftailEditorWithVariables
          label={this.props.label}
          rawContentState={validatedDratailObject}
          onSaveHandler={this.onSaveHandler}
          placeholder={placeholder}
          variableButtonClassName='variable-selector'
          // Only show the variables if not form
          variableGroups={
            activeChannel !== 'form' ? variableMenuData : undefined
          }
        />
        <FormHelperText>{helperText}</FormHelperText>
      </StyledVariableTextInput>
    );
  }
}
