import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';
import { StyledTableWrapperProps } from './DataTable.types';

export const StyledTableWrapper = styled.div<StyledTableWrapperProps>`
  background-color: ${Colors.white};
  height: ${({ maxHeight }) => maxHeight || '100%'};

  .MuiTableContainer-root {
    width: auto;
    position: relative;
    /* 52px is the height of the pagination footer */
    height: calc(100% - 52px);

    .MuiTable-root {
      &.spinner-wrapper {
        .MuiTableBody-root {
          display: flex;
          align-items: center;
          justify-content: center;
          height: calc(100% - 52px);
          width: 100%;
          position: absolute;
        }
      }

      .MuiTableCell-root {
        padding: 12px 8px;
        ${Fonts.body};
        line-height: 24px;

        &.MuiTableCell-head {
          padding: 8px;
        }

        &:first-child {
          padding-left: 32px;
        }

        &:last-child {
          padding-right: 24px;
        }
      }
    }

    &.select-mode-multi {
      .MuiTableCell-head.MuiTableCell-root {
        padding: 4px 8px 4px 0;

        &:first-child {
          padding-left: 32px;
        }

        &:last-child {
          padding-right: 24px;
        }
      }
    }
  }

  .select-mode-single,
  .select-mode-multi {
    .MuiTableRow-root:hover {
      cursor: pointer;
      background-color: ${Colors.grey_blue_0};
    }
  }

  .select-mode-none {
    .MuiTableRow-root:hover {
      cursor: default;
    }
  }

  .MuiTableCell-head.MuiTableCell-root {
    ${Fonts.caption};
    color: ${Colors.grey_blue_4};

    .MuiTableSortLabel-active {
      .sort-arrow-icon {
        display: initial;
      }

      .MuiTableSortLabel-icon path {
        fill: ${Colors.accent};
      }
    }

    .MuiTableSortLabel-root {
      color: ${Colors.grey_blue_4};

      &.MuiTableSortLabel-active {
        color: ${Colors.grey_blue};
        font-weight: 500;
      }
    }

    &.MuiTableCell-stickyHeader {
      background-color: ${Colors.white};
    }
  }

  .MuiTableBody-root {
    .MuiTableCell-root {
      border-bottom: 1px solid ${Colors.grey_blue_1};

      &.checkbox-cell {
        border-bottom: none;
      }
    }
  }

  .MuiTableRow-root {
    background-color: ${Colors.white};

    .messageName-cell {
      width: 20%;
    }

    &.row-selected {
      background-color: ${Colors.accent_1};
    }
  }

  .checkbox-cell {
    min-width: 0;
    width: 70px;
    box-sizing: border-box;

    .checkbox-control-form {
      margin-right: 3px;
    }

    .PrivateSwitchBase-root-1 {
      padding: 6px;
    }

    .MuiCheckbox-colorPrimary {
      color: ${Colors.grey_blue_4};

      &.Mui-checked {
        color: ${Colors.accent};
      }
    }

    .MuiSvgIcon-fontSizeSmall {
      font-size: 14px;
    }
  }

  .id-cell {
    min-width: 60px;
  }

  .sort-arrow-icon {
    height: 8px;
    display: none;
    margin: 0;
    width: 12px;
  }

  .sort-icon {
    margin: 0;
    width: 12px;
  }

  .MuiTablePagination-root {
    ${Fonts.caption};
    border-top: 1px solid ${Colors.grey_blue_1};
    color: ${Colors.grey_blue_4};
    /* Added to accommodate Intercom */
    :last-child {
      padding-right: 96px;
    }

    .MuiTablePagination-input,
    .MuiTablePagination-caption:first-of-type {
      order: -1;
    }

    .MuiTablePagination-caption {
      ${Fonts.caption};
    }

    .MuiTablePagination-select {
      text-align-last: left;
    }

    .MuiTablePagination-actions {
      .MuiButtonBase-root {
        padding: 8px;

        .MuiSvgIcon-root {
          color: ${Colors.grey_blue};
        }

        &.Mui-disabled .MuiSvgIcon-root {
          color: ${Colors.grey_blue_2};
        }
      }
    }
  }

  .n-rows-selected-label {
    ${Fonts.caption};
  }

  .select-all-button {
    display: inline;
    line-height: 16px;

    .content,
    .MuiButton-root {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .batch-action-button,
  .select-all-button {
    margin: 0 16px;
  }

  .MuiTableCell-root.row-action-cell {
    position: sticky;
    right: 0;
    background-color: inherit;
    width: 20px;
    text-align: center;
    padding-right: 24px;

    .MuiIconButton-root {
      height: 32px;
      width: 32px;
    }
  }
`;
