/* eslint-disable max-lines */
import { Colors } from '../Foundation/Colors';
import { AvailableFilters, SelectedFilter } from '../Molecules/FilterPicker';
import {
  ToneCardDataArrayType,
  ToneScoreArrayType,
  ToneSettingsType,
} from './ToneOfVoice/toneOfVoiceTypes';

export const toneScoresArray: ToneScoreArrayType = [
  {
    tone: 'joyful',
    score: 2,
    desiredToneIntensity: 'Strong',
    messageToneIntensity: 'Strong',
  },
  {
    tone: 'surprised',
    score: 2,
    desiredToneIntensity: 'Strong',
    messageToneIntensity: 'Strong',
  },
  {
    tone: 'sad',
    score: 2,
    desiredToneIntensity: 'Low',
    messageToneIntensity: 'Low',
  },
  {
    tone: 'warm',
    score: 1,
    desiredToneIntensity: 'Strong',
    messageToneIntensity: 'Moderate',
  },
  {
    tone: 'angry',
    score: 0,
    desiredToneIntensity: 'Low',
    messageToneIntensity: 'Strong',
  },
  {
    tone: 'fearful',
    score: 0,
    desiredToneIntensity: 'Low',
    messageToneIntensity: 'Strong',
  },
];

// Text for the descriptions for both scores of each tone to be supplied.
// High score is always the same text for each tone, so is exported seperately below
export const toneCardDataArray: ToneCardDataArrayType = [
  {
    tone: 'joyful',
    data: {
      emoji: '🙂',
      descriptions: {
        low: {
          lower:
            'This message seems joyful. Consider using more serious or formal language instead.',
          higher: `This message seems too joyful. Consider rephrasing sections with upbeat language, and add more formal or serious words.`,
        },
        moderate: {
          lower:
            'This message is a bit serious. Consider using more cheerful or upbeat language instead.',
          higher:
            'This message seems too joyful. Consider using more serious or formal language instead.',
        },
        strong: {
          lower: `This message is very serious. Consider rephrasing overly formal sections, and add more cheerful or upbeat language.`,
          higher:
            'This message is a bit too serious. Consider using more cheerful or upbeat language instead.',
        },
      },
      example: `"We're delighted to have you with us today."`,
    },
  },
  {
    tone: 'surprised',
    data: {
      emoji: '😮',
      descriptions: {
        low: {
          lower:
            'This message seems surprised. Consider using more objective or stoic language instead.',
          higher: `This message seems too surprised. Consider rephrasing sections which appear hesitant or uncertain, and add more objective or stoic language.`,
        },
        moderate: {
          lower:
            'This message seems a bit "matter-of-fact". Consider using more punchy or excited language instead.',
          higher:
            'This message seems too surprised. Consider using more objective or stoic language instead.',
        },
        strong: {
          lower: `This message seems very "matter-of-fact". Consider rephrasing sections which appear too deadpan, and add more lively or excited language instead.`,
          higher:
            'This message is a bit too "matter-of-fact". Consider using more punchy or excited language instead.',
        },
      },
      example: `"We can't believe the great feedback we've been getting!"`,
    },
  },
  {
    tone: 'sad',
    data: {
      emoji: '😔',
      descriptions: {
        low: {
          lower:
            'This message seems sad. Consider using happier or more hopeful language instead.',
          higher: `This message seems too sad. Consider replacing sections which appear somber or gloomy, and add happier or more hopeful language.`,
        },
        moderate: {
          lower:
            'This message seems a bit too jubilant. Consider using more somber or apologetic language instead.',
          higher:
            'This message seems too sad. Consider using happier or more hopeful language instead.',
        },
        strong: {
          lower: `This message seems overly jubilant. Consider toning down enthusiastic language, and adding more somber or apologetic phrases.`,
          higher:
            'This message seems a bit overjoyed. Consider using more somber or apologetic language instead.',
        },
      },
      example: `"I'm sorry to see you go."`,
    },
  },
  {
    tone: 'warm',
    data: {
      emoji: '🤗',
      descriptions: {
        low: {
          lower:
            'This message seems warm. Consider using cooler or more restrained language instead.',
          higher: `This message seems too warm. Consider rephrasing overly-friendly sections, and add cooler or more restrained language.`,
        },
        moderate: {
          lower:
            'This message seems a bit restrained. Consider using friendlier or more pleasant language instead.',
          higher:
            'This message seems too warm. Consider using cooler or more restrained language instead.',
        },
        strong: {
          lower: `This message seems very restrained. Consider rephrasing sections which appear aloof or unsympathetic, and add more friendly language instead.`,
          higher:
            'This message is a bit too restrained. Consider using friendlier or more pleasant language instead.',
        },
      },
      example: `'I really loved your presentation.'`,
    },
  },
  {
    tone: 'angry',
    data: {
      emoji: '😡',
      descriptions: {
        low: {
          lower:
            'This message seems angry. Consider using more pleasant or calm language instead.',
          higher: `This message seems too angry. Consider removing words which seem aggressive or abrasive, and add more pleasant or calm language.`,
        },
        moderate: {
          lower:
            'This message seems a bit gentle. Consider using louder or more assertive language instead.',
          higher:
            'This message seems too angry. Consider using more pleasant or calm language instead.',
        },
        strong: {
          lower: `This message seems overly gentle. Consider removing placid language, and add louder or more assertive phrases.`,
          higher:
            'This message is a bit too gentle. Consider using louder or more assertive language instead.',
        },
      },
      example: `"We're just as infuriated about this situation as you are."`,
    },
  },
  {
    tone: 'fearful',
    data: {
      emoji: '😨',
      descriptions: {
        low: {
          lower:
            'This message seems fearful. Consider using more bold or confident language instead.',
          higher: `This message seems too fearful. Consider rewording sections which appear anxious or timid, and add more bold or confident language.`,
        },
        moderate: {
          lower:
            'This message seems a bit too confident. Consider using more modest or reserved language instead.',
          higher:
            'This message seems too fearful. Consider using more bold or confident language instead.',
        },
        strong: {
          lower: `This message seems overly confident. Consider toning down bold or impulsive language, and add more modest or reserved phrases.`,
          higher:
            'This message seems a bit too confident. Consider using more modest or reserved language instead.',
        },
      },
      example: `"I'm worried that I won't be able to make it."`,
    },
  },
];

export const toneScoreHighDescription = 'You’ve hit your target for this tone.';

export const toneOfVoiceCards = [
  {
    icon: '🙂',
    title: 'joyful',
    description: `"We're delighted to have you with us today."`,
  },
  {
    icon: '🤗',
    title: 'warm',
    description: `'I really loved your presentation.'`,
  },
  {
    icon: '😮',
    title: 'surprised',
    description: `"We can't believe the great feedback we've been getting!"`,
  },
  {
    icon: '😡',
    title: 'angry',
    description: `"We're just as infuriated about this situation as you are."`,
  },
  {
    icon: '😨',
    title: 'fearful',
    description: `"I'm worried that I won't be able to make it."`,
  },
  {
    icon: '😔',
    title: 'sad',
    description: `"I'm sorry to see you go."`,
  },
];

export const companySettingToneValues: ToneSettingsType = {
  joyful: 'low',
  warm: 'medium',
  surprised: 'high',
  angry: 'low',
  fearful: 'low',
  sad: 'low',
};

export const campaignSettingToneValues: ToneSettingsType = {
  joyful: 'high',
  warm: 'high',
  surprised: 'medium',
  angry: 'low',
  fearful: 'medium',
  sad: 'low',
};

export const toneOfVoicePredictionResponse = {
  requestMessage:
    'This is an example string that can be sent as part of requestBody on predict endpoint. It will be broken down into individual sentences and tone of voice predictions are done on each of those sentences.',
  language: 'en',
  predictions: [
    {
      text:
        'This is an example string that can be sent as part of requestBody on predict endpoint.',
      tones: [
        { tone: 'sadness', score: 0.90731930285692215 },
        { tone: 'joy', score: 0.6860160231590271 },
        { tone: 'love', score: 0.418101556226611137 },
        { tone: 'anger', score: 0.93734368979930878 },
        { tone: 'fear', score: 0.945187219977378845 },
        { tone: 'surprise', score: 0.406032214965671301 },
      ],
    },
    {
      text:
        'It will be broken down into individual sentences and tone of voice predictions are done on each of those sentences.',
      tones: [
        { tone: 'sadness', score: 0.41850092560052872 },
        { tone: 'joy', score: 0.722801148891449 },
        { tone: 'love', score: 0.00063288003206253 },
        { tone: 'anger', score: 0.08368270099163055 },
        { tone: 'fear', score: 0.05984204635024071 },
        { tone: 'surprise', score: 0.7045403665862977505 },
      ],
    },
  ],
  metadata: {
    wspRegion: 'AU',
    countryCode: 'AU',
    companyId: 1174,
    userId: 34606,
    workspaceId: 10591,
    requestTimeIsoUtc: '2021-05-03T09:41:00.819Z',
    requestTimezoneId: 'Australia/Melbourne',
    modelSpec: {
      modelName: 'Tone of Voice Analyser',
      modelVersion: '1.0.0-alpha',
    },
    requestId: '59ea048a-26c7-4b28-afa6-e61a31d1c99d',
    responseTimeIsoUtc: '2021-05-03T02:10:46.598Z',
  },
};

export const exampleLineChartData = [
  {
    id: 'smsSent',
    color: Colors.variable,
    data: [
      {
        x: '12pm',
        y: 0,
      },
      {
        x: '1pm',
        y: 280,
      },
      {
        x: '2pm',
        y: 240,
      },
      {
        x: '3pm',
        y: 350,
      },
      {
        x: '4pm',
        y: 260,
      },
      {
        x: '5pm',
        y: 80,
      },
      {
        x: '6pm',
        y: 150,
      },
      {
        x: '7pm',
        y: 290,
      },
      {
        x: '8pm',
        y: 340,
      },
      {
        x: '9pm',
        y: 300,
      },
      {
        x: '10pm',
        y: 330,
      },
      {
        x: '11pm',
        y: 250,
      },
      {
        x: '12am',
        y: 500,
      },
    ],
  },
];

export const exampleBarChartData = [
  {
    type: 'smsActivity',
    'SMS Sent': 24950,
    'SMS Received': 5861,
    'SMS Failed': 540,
  },
];

export const exampleBarChartDataWithNewStatuses = [
  {
    type: 'smsActivity',
    'SMS Sent': 24900,
    'SMS Received': 5861,
    'SMS Failed': 540,
    'SMS Read': 50,
  },
];

export const indexedBarChartData = [
  {
    type: 'smsReplied',
    Replied: 0,
  },
  {
    type: 'smsFailed',
    Failed: 0,
  },
  {
    type: 'smsReceived',
    Received: 1,
  },
];

export const exampleEmptyBarChartData = [
  {
    type: 'smsActivity',
    'SMS Sent': 0,
    'SMS Received': 0,
    'SMS Failed': 0,
  },
];

export const exampleBarChartLegendItems = {
  'SMS Received': {
    color: Colors.accent,
    description: 'Message has been sent and received by recipient device.',
  },
  'SMS Failed': {
    color: Colors.grey_blue_3,
    description:
      'Message was sent but was unable to be delivered to the recipient. \nThis might be due to an invalid number, carrier service issue or other reasons.',
  },
  'SMS Sent': {
    color: Colors.blue,
    description:
      'Message has been sent, but may or may not have yet been received by recipient device.',
  },
};

export const indexedBarChartLegendItems = {
  Received: {
    color: Colors.accent,
    description: 'SMS Received',
  },
  Failed: {
    color: Colors.grey_blue_3,
    description: 'SMS Failed',
  },
  Replied: {
    color: Colors.blue,
    description: 'SMS Replied',
  },
};

export const exampleTabs = [
  {
    id: 1,
    name: 'example 1',
    content: 'Please write description here',
  },
  {
    id: 2,
    name: 'example 2',
    content: 'Please write all your features here',
  },
  {
    id: 3,
    name: 'example 3',
    icon: 'Messages',
    content: 'Please write all your documentation here',
  },
];

export const exampleVariableGroups: Array<any> = [
  {
    groupName: 'System Variables',
    groupType: 'systemVariables',
    variables: [
      {
        variableId: 'one',
        variableName: 'Variable One',
        type: 'IUX',
        groupName: 'System Variables',
        groupType: 'systemVariables',
      },
      {
        variableId: 'two',
        variableName: 'Variable Two',
        type: 'IUX',
        groupName: 'System Variables',
        groupType: 'systemVariables',
      },
    ],
  },
  {
    groupName: 'Group Two',
    groupType: 'foo',
    variables: [
      {
        stepId: 2,
        variableId: 'three',
        variableName: 'Variable three',
        type: 'foo',
        groupName: 'Group Two',
        groupType: 'foo',
      },
    ],
  },
  {
    groupName: 'Group Three',
    groupType: 'foo',

    variables: [
      {
        stepId: 3,
        variableId: 'four',
        variableName: 'Has no groupname/type',
        type: 'foo',
      },
    ],
  },
];

export const exampleAvailableFilters: Array<AvailableFilters> = [
  {
    icon: 'Person',
    label: 'Creator',
    columnKey: 'creator',
    dataType: 'Text',
  },
  {
    icon: 'DefaultTrigger',
    label: 'Trigger Type',
    columnKey: 'triggerType',
    dataType: 'Text',
  },
  {
    icon: 'DefaultTrigger',
    label: 'Last Triggered',
    columnKey: 'lastTriggered',
    dataType: 'Date',
  },
  {
    icon: 'Calendar',
    label: 'Date Created',
    columnKey: 'dateCreated',
    dataType: 'Date',
  },
];

export const exampleSelectedFilters: Array<SelectedFilter> = [
  {
    icon: 'Person',
    label: 'Creator',
    columnKey: 'filterKey',
    dataType: 'Text',
    condition: {
      operator: 'equals',
      operand: {
        value: '12345',
        valueType: 'string',
      },
    },
  },
  {
    icon: 'Calendar',
    label: 'Date Created',
    columnKey: 'dateCreated',
    dataType: 'Date',
    condition: {
      operator: 'equals',
      operand: {
        value: '2020-09-30T16:00:00.000Z',
        valueType: 'date',
      },
    },
  },
];
