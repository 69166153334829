import styled from 'styled-components';

import { Fonts } from '../../../Foundation/Fonts';

export const AccordionWrapper = styled.div`
  .MuiIconButton-label {
    width: 40%;
    transform: matrix(0, 1, 1, 0, 0, 0);
  }
  .MuiAccordionSummary-root.Mui-expanded {
    ${Fonts.body};
    min-height: 24px;
  }
  .MuiButtonBase-root.MuiAccordionSummary-root {
    height: 16px;
    min-height: 16px;
    padding-top: 16px;
  }
  .MuiTypography-body1 {
    ${Fonts.body};
    color: #1d1e2e;
  }
  .MuiPaper-elevation1 {
    box-shadow: none;
  }
  .MuiAccordionSummary-expandIcon {
    padding: 0px;
    margin-left: 8px;
  }
  .MuiAccordionSummary-expandIcon.Mui-expanded {
    transform: rotate(90deg);
  }
  .MuiAccordionSummary-root {
    padding: 0px;
    width: fit-content;
  }
  .MuiAccordionDetails-root {
    display: block;
    width: 100%;
    padding: 10px 0 0 24px;
    box-sizing: border-box;
  }
`;
