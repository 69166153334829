import React, { useRef } from 'react';

import { Divider as MUIDivider } from '@material-ui/core';

import { Icon } from '../../Atoms/Icon';
import { StyledCopyText } from './CopyText.style';
import { CopyTextTypes } from './CopyText.types';

export const CopyText = ({
  text,
  successMessage = '',
  onClick = () => null,
  warningText = '',
  helperText = '',
}: CopyTextTypes): JSX.Element => {
  const textToCopy = useRef<HTMLInputElement>(null);

  const handleOnClickCopy = () => {
    const { current } = textToCopy;

    if (current) {
      current.select();
      document.execCommand('copy');
      onClick(successMessage || 'Copied to clipboard');
    }
  };

  return (
    <StyledCopyText>
      {helperText && <span className='helper-text'>{helperText}</span>}
      <div className='copy-text-wrapper'>
        <input
          className='text-to-copy'
          ref={textToCopy}
          value={text}
          readOnly
        />
        <MUIDivider orientation='vertical' flexItem />
        <button
          data-button='copy-to-clipboard'
          type='button'
          className='copy-button'
          onClick={handleOnClickCopy}
        >
          <Icon icon='Copy' />
        </button>
      </div>
      {warningText && (
        <div className='warning-wrapper'>
          <Icon icon='WarningOutline' />
          <span className='warning-text'>{warningText}</span>
        </div>
      )}
    </StyledCopyText>
  );
};
